* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #151515;
  color: #e5e5e5;
  font-size: 0.7rem !important;
  user-select: none;
  font-family: "Quicksand", sans-serif !important;
  text-align: center;
}

a:link,
a:visited,
a:active {
  text-decoration: none !important;
  color: #e5e5e5 !important;
}

.navLinks {
  background-color: #242424;
  border: 1px solid #424242;
  border-radius: 0.5rem;
}

.logotext {
  font-size: 1rem !important;
  font-family: "WindSong", cursive !important;
  color: #fff;
}

.rowbottom {
  border-bottom: 1px solid #424242;
}
